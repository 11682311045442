<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-4">
      <FwbInput
        v-model="v.username.$model"
        class="bg-transparent text-send-grey-100"
        placeholder="username"
        @keyup.enter.stop="!v.$invalid && loginWithAccount()"
      >
      </FwbInput>
      <FwbInput
        v-model="v.password.$model"
        class="bg-transparent text-send-grey-100"
        type="password"
        placeholder="password"
        @keyup.enter.stop="!v.$invalid && loginWithAccount()"
      >
      </FwbInput>
      <div class="border-t border-send-grey-20" />

      <div class="flex gap-4 justify-between items-center">
        <NuxtLink to="/login/recover">{{ $t("forgot_password") }}?</NuxtLink>
        <FwbButton
          color="dark"
          :loading="loginUsingAccount.loading"
          :disabled="v.$invalid"
          @click="loginWithAccount"
        >
          <template v-if="loginUsingAccount.loading">
            {{ $t("logging_in") }}...
          </template>
          <template v-else> {{ $t("log_in") }} </template>
        </FwbButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FwbInput from "~/components/Flowbite/FwbInput/FwbInput.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import { useAuthStore } from "~/stores/auth";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toastification";

const form = reactive({
  password: "",
  username: "",
});

const toast = useToast();

const emit = defineEmits(["login"]);

const { loginUsingAccount } = useAuthStore();

const v = useVuelidate(
  {
    username: { required },
    password: { required },
  },
  form
);

const loginWithAccount = async () => {
  await loginUsingAccount.mutate({
    username: form.username,
    password: form.password,
  });
};

loginUsingAccount.onDone((result) => {
  if (result.data?.login.accessToken) emit("login", result);
});

loginUsingAccount.onError((error) => {
  toast.error(error.message);
});
</script>
